*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    color: white;
    height: 70px;
    top: 0;
    position: sticky;
    z-index: 9999;
  }
  .logo {
    font-size: 30px;
  }
  .nav__links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 75%;
    cursor: pointer;
  }
  
  .home,
  .skills,
  .contact,
  .about,
  .projects {
    text-decoration: none;
    color: white;
    font-size: 20px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .mobile__menu__icon {
    border: 0;
    height: 15px;
    font-size: 30px;
    list-style: none;
    display: none;
  }
  
  @media screen and (max-width: 780px) {
    .logo {
      display: flex;
      position: absolute;
      top: 15px;
      left: 35px;
    }
    .nav__links {
      display: none;
    }  
    .nav__links__mobile {
      position: absolute;
      display: block;
      list-style: none;
      background-color: #050A30;
      color: white;
      left: 0;
      top: 65px;
      transition: all 0.5s ease-out;
      width: 100%;
    }
    .home,
    .skills,
    .contact,
    .about,
    .projects {
      text-align: center;
      padding: 30px;
      transition: all 0.5s ease-out;
    }
    .mobile__menu__icon {
      display: block;
      position: absolute;
      font-size: 30px;
      color: white;
      outline: none;
      top: 15px;
      right: 30px;
      border: none;
      background: black;
    }
  }
  